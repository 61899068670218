// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function BacksvgIcon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 288.312 288.312"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",
        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M127.353 3.555c-4.704-4.74-12.319-4.74-17.011 0L15.314 99.653c-4.74 4.788-4.547 12.884.313 17.48l94.715 95.785c4.704 4.74 12.319 4.74 17.011 0 4.704-4.74 4.704-12.427 0-17.167l-74.444-75.274h199.474v155.804c0 6.641 5.39 12.03 12.03 12.03 6.641 0 12.03-5.39 12.03-12.03v-168.05c0-6.641-5.39-12.03-12.03-12.03H52.704l74.648-75.49c4.704-4.729 4.704-12.416.001-17.156z"
        }
      ></path>
    </svg>
  );
}

export default BacksvgIcon;
/* prettier-ignore-end */
