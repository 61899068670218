// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function TourismsvgIcon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 373 526"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",
        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M299.67 437.91c3.062 0 5.469-2.406 5.469-5.469v-196.88c0-3.062-2.406-5.469-5.469-5.469s-5.469 2.406-5.469 5.469v196.88c0 3.063 2.516 5.469 5.469 5.469zm-45.06 0c3.062 0 5.469-2.406 5.469-5.469v-196.88c0-3.062-2.407-5.469-5.469-5.469-3.063 0-5.469 2.406-5.469 5.469v196.88c0 3.063 2.406 5.469 5.469 5.469zm-45.17 0c3.063 0 5.469-2.406 5.469-5.469v-196.88c0-3.062-2.406-5.469-5.469-5.469s-5.469 2.406-5.469 5.469v196.88c0 3.063 2.516 5.469 5.469 5.469zm-45.06 0c3.062 0 5.469-2.406 5.469-5.469v-196.88c0-3.062-2.406-5.469-5.469-5.469s-5.469 2.406-5.469 5.469v196.88c0 3.063 2.406 5.469 5.469 5.469zm-45.18 0c3.062 0 5.469-2.406 5.469-5.469v-196.88c0-3.062-2.407-5.469-5.469-5.469-3.063 0-5.469 2.406-5.469 5.469v196.88c0 3.063 2.516 5.469 5.469 5.469zm-45.06 0c3.062 0 5.469-2.406 5.469-5.469v-196.88c0-3.062-2.407-5.469-5.47-5.469-3.062 0-5.468 2.406-5.468 5.469v196.88c0 3.063 2.406 5.469 5.469 5.469z"
        }
        fill={"currentColor"}
      ></path>

      <path
        d={
          "M282.61 77.078V5.875c0-3.062-2.406-5.469-5.469-5.469H96.671c-3.062 0-5.469 2.406-5.469 5.47v71.202C41.108 79.266.968 119.625.968 168.844v231.88c0 49.219 40.141 89.578 90.234 91.766v27.453c0 3.063 2.407 5.469 5.47 5.469h45.171c3.063 0 5.469-2.406 5.469-5.469v-27.344h79.297v27.344c0 3.063 2.406 5.469 5.469 5.469h45.172c3.062 0 5.468-2.406 5.468-5.469V492.49c50.094-2.187 90.234-42.547 90.234-91.766v-231.88c-.109-49.219-40.25-89.578-90.344-91.766h.002zM102.14 11.344h169.53v65.625H102.14V11.344zm34.234 503.12H102.14v-21.875h34.234v21.875zm135.3 0H237.44v-21.875h34.234v21.875zm90.234-113.75c0 44.625-37.516 80.938-83.672 80.938H95.466c-46.156 0-83.672-36.312-83.672-80.938v-214.38h350v214.38h.114zm0-225.31h-350v-6.562c0-44.625 37.516-80.939 83.672-80.939h182.66c46.156 0 83.672 36.313 83.672 80.939l-.004 6.562z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default TourismsvgIcon;
/* prettier-ignore-end */
