// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function BackX2SvgIcon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 240.877 240.877"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",
        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M113.983 120.483l98.564-99.671c4.704-4.752 4.704-12.439 0-17.191-4.704-4.74-12.319-4.74-17.011 0L88.466 111.882c-4.632 4.668-4.547 12.584 0 17.179l107.07 108.261c4.704 4.74 12.319 4.74 17.011 0 4.692-4.74 4.704-12.439 0-17.179l-98.564-99.66zM36.832 0c-6.641 0-12.03 5.39-12.03 12.03v216.545c0 6.641 5.39 12.03 12.03 12.03s12.03-5.39 12.03-12.03V12.03C48.863 5.378 43.473 0 36.832 0z"
        }
      ></path>
    </svg>
  );
}

export default BackX2SvgIcon;
/* prettier-ignore-end */
