// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function RealEstatesvgIcon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 532 431"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",
        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M496.16 416.04V.52H156.24v68.879H35.84v346.64H0v14h532v-14l-35.84.001zm-14-402.08v401.52h-114.8v-97.441h-82.32v98H169.68V13.959l312.48.001zm-128.8 402.08h-54.879v-84h54.879v84zM49.84 82.84h106.4v333.2H49.84V82.84z"
        }
        fill={"currentColor"}
      ></path>

      <path
        d={
          "M246.96 43.64H198.8V91.8h48.16V43.64zM233.519 77.8H212.8V57.081h20.719v20.72zm82.321-34.16h-48.16V91.8h48.16V43.64zm-14 34.16h-20.719V57.081h20.719v20.72zm82.32-34.16H336V91.8h48.16V43.64zM370.719 77.8H350V57.081h20.719v20.72zm82.321-34.16h-48.16V91.8h48.16V43.64zM439.602 77.8h-20.719V57.081l20.719-.004V77.8zM246.96 111.96H198.8v48.16h48.16v-48.16zm-13.441 34.16H212.8v-20.719h20.719v20.719zm82.321-34.16h-48.16v48.16h48.16v-48.16zm-14 34.16h-20.719v-20.719h20.719v20.719zm82.32-34.16H336v48.16h48.16v-48.16zm-13.441 34.16H350v-20.719h20.719v20.719zm82.321-34.16h-48.16v48.16h48.16v-48.16zm-13.438 34.16h-20.719v-20.719l20.719-.004v20.723zM246.96 180.84H198.8V229h48.16v-48.16zM233.519 215H212.8v-20.719h20.719V215zm82.321-34.16h-48.16V229h48.16v-48.16zm-14 34.16h-20.719v-20.719h20.719V215zm82.32-34.16H336V229h48.16v-48.16zM370.719 215H350v-20.719h20.719V215zm82.321-34.16h-48.16V229h48.16v-48.16zM439.602 215h-20.719v-20.719h20.719V215zM246.96 249.16H198.8v48.16h48.16v-48.16zm-13.441 34.719H212.8V263.16h20.719v20.719zm82.321-34.719h-48.16v48.16h48.16v-48.16zm-14 34.719h-20.719V263.16h20.719v20.719zm82.32-34.719H336v48.16h48.16v-48.16zm-13.441 34.719H350V263.16h20.719v20.719zm82.321-34.719h-48.16v48.16h48.16v-48.16zm-13.438 34.719h-20.719V263.16h20.719v20.719zM246.96 318.04H198.8v48.16h48.16v-48.16zm-13.441 34.16H212.8v-20.719h20.719V352.2zm219.521-34.16h-48.16v48.16h48.16v-48.16zm-13.438 34.16h-20.719v-20.719h20.719V352.2zM78.96 111.96v48.16h48.16v-48.16H78.96zm34.16 34.16H92.397v-20.719h20.719l.004 20.719zM78.96 229h48.16v-48.16H78.96V229zm13.438-34.719h20.719l.004 20.719H92.398v-20.719zM78.96 297.32h48.16v-48.16H78.96v48.16zm13.438-34.16h20.719v20.719h-20.72V263.16zM78.96 366.2h48.16v-48.16H78.96v48.16zm13.438-34.719h20.719V352.2h-20.72v-20.719z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default RealEstatesvgIcon;
/* prettier-ignore-end */
