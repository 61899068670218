// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function RetailsvgIcon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 452 438"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",
        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M84.03 156.97c-27.672-.93-56.656-24.555-72.406-50.367C-.954 85.823-2.54 64.111 7.194 45.244c18.922-36.477 57.641-17.938 80.61-6.89 4.43 2.187 8.476 4.101 12.249 5.687 10.719 4.43 28 4.43 34.945-2.188 6.289-5.96 3.774-17.938.328-26.742-2.187-5.359.657-11.32 5.961-13.507 5.305-2.188 11.32.656 13.508 5.96 10.062 26.742 2.844 41.562-5.359 49.438-15.422 14.82-43.094 12.906-57.586 6.618-4.102-1.914-8.477-3.774-13.234-5.961-30.516-15.094-44.68-18.594-52.555-2.844-8.203 15.75-2.516 30.844 3.445 40.906 11.977 19.523 37.79 34.617 58.242 36.203 1.258 0 3.446 1.586 3.172 3.445l-1.914 17.609c-.219-.054-1.477 3.992-4.977 3.992h.001z"
        }
        fill={"currentColor"}
      ></path>

      <path
        d={
          "M168.09 128.97c4.703 1.258 9.133 3.773 12.578 7.219 12.25 11.977 12.578 31.172.328 43.094-12.25 11.977-32.102 12.25-44.406.328-12.25-11.977-12.578-31.172-.328-43.094 2.516-2.516 5.359-4.102 8.203-5.961 1.258-.656 1.586-1.914 1.586-2.844 1.586-10.062.656-21.109.328-25.812 0-1.257-1.914-1.914-2.515-1.914-9.461 1.914-19.195 4.102-29.586 6.946-3.446.929-5.688 2.843-6.289 6.289-15.095 65.461-11.32 104.18-9.735 115.17.328 2.187 1.258 3.773 2.516 5.031 14.164 14.492 107.35 108.61 209.29 200.48 2.187 1.914 7.218 5.687 11.32 2.187 45.664-35.547 91.602-77.438 128.41-126.22 2.844-3.773.328-9.461-1.914-11.32-94.391-97.508-192.94-185.34-209.62-200.1-1.586-1.586-3.446-2.187-5.36-2.515-7.875-1.258-32.102-3.774-66.391.328-.656 0-2.187 1.258-2.187 2.844-.328 5.031-.657 16.68 1.586 26.742.273 1.257.929 2.789 2.187 3.117l-.001.005z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default RetailsvgIcon;
/* prettier-ignore-end */
